import 'bootstrap';
import 'bootstrap/js/src/collapse';
import 'spotlight.js/src/js/spotlight';


import videojs from 'video.js';
// import models from '@data/models.json';

import Swiper from 'swiper';
import { Autoplay, Navigation, Pagination, EffectFade, Parallax } from 'swiper/modules';

// import Swiper styles
import 'swiper/css';
// import 'video.js/src/css/video-js.scss';

import NiceSelect from 'nice-select2';

const swiper = new Swiper('[data-swiper]', {
    loop: true,
    autoplay: {
        delay: 5000,
    },
    parallax: true,
    speed: 2500,
    effect: 'fade',
    fadeEffect: {
        crossFade: true
    },

    // configure Swiper to use modules
    modules: [Navigation, Pagination, EffectFade, Autoplay, Parallax],
});

const swiperDetail = new Swiper('[data-swiper-portrait]', {
    loop: true,
    autoplay: false,
    parallax: false,
    speed: 1500,
    effect: 'slide',
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },

    // configure Swiper to use modules
    modules: [Navigation, Pagination, EffectFade, Autoplay, Parallax],
});


document.addEventListener('DOMContentLoaded', (event) => {

    let videos = document.querySelectorAll('.video-muted');
    videos.forEach((video) => {
        video.pause();
    });


});

if(null !== document.getElementById("interesse")) {
    new NiceSelect(document.getElementById("interesse"), {
        searchable: false,
        placeholder: 'Ich interessiere mich für*',
        searchtext: 'search'
    });
}

let acItem = window.location.pathname.split('/').pop().replace('.html', '');
let hlItem = document.querySelector('[data-active="'+acItem+'"]');
if(null !== hlItem) {
    hlItem.classList.add('active');
}
